import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#f7f9f9',
    color100: '#f0f3f3',
    color200: '#e1e7e8',
    color300: '#d9e1e2',
    color400: '#b8bdbf',
    color500: '#97999b',
    color600: '#75787b',
    color700: '#53565a',
    color800: '#37393c',
    color900: '#1c1d1e'
  },
  default: {
    color50: '#d6e1ec',
    color100: '#adc4d9',
    color200: '#85a6c6',
    color300: '#5c88b2',
    color400: '#336b9f',
    color500: '#0a4d8c',
    color600: '#083e70',
    color700: '#062e54',
    color800: '#041f38',
    color900: '#020f1c'
  },
  accent: {
    color50: '#d5edf4',
    color100: '#aadbe9',
    color200: '#80c9de',
    color300: '#55b6d2',
    color400: '#2ba4c7',
    color500: '#0092bc',
    color600: '#007596',
    color700: '#005871',
    color800: '#003a4b',
    color900: '#001d26'
  },
  positive: {
    color50: '#e0f1de',
    color100: '#c1e4bd',
    color200: '#a2d69d',
    color300: '#82c87c',
    color400: '#63bb5b',
    color500: '#44ad3a',
    color600: '#368a2e',
    color700: '#296823',
    color800: '#1b4517',
    color900: '#0e230c'
  },
  negative: {
    color50: '#f9d8da',
    color100: '#f4b0b5',
    color200: '#ee8990',
    color300: '#e8626b',
    color400: '#e33a46',
    color500: '#dd1321',
    color600: '#b10f1a',
    color700: '#850b14',
    color800: '#58080d',
    color900: '#2c0407'
  },
  special: {
    color50: '#f9d8da',
    color100: '#f4b0b5',
    color200: '#ee8990',
    color300: '#e8626b',
    color400: '#e33a46',
    color500: '#dd1321',
    color600: '#b10f1a',
    color700: '#850b14',
    color800: '#58080d',
    color900: '#2c0407'
  }
};

const variant: ACPVariant = {
  id: 'www.meijerprepaidcard.com',
  productTitle: 'Meijer Visa Prepaid Card',
  productType: 'gpr',
  accessDomain: {
    subdomain: 'www',
    domain: 'meijerprepaidcard',
    topLevelDomain: 'com'
  },
  colors,
  legacyAcpTheme: 'meijer',
  legacyAcpBrandName: 'meijer',
  legacyLegosTheme: 'meijer',
  status_bar_background_color: colors.default.color500,
  status_bar_foreground_color: 'light',
  contactInfo: {
    name: 'Meijer Visa Prepaid Card',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'CustomerService@netspend.com',
    fax_formatted: '(512) 857-0263',
    phone_formatted: '(866) 601-1070',
    phone: '8666011070',
    phone_international: '737-220-8956',
    balance_check_phone: '8663877363',
    balance_check_phone_formatted: '1-866-387-7363',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      appsFlyerBannerKey: '6c09be05-fc27-483b-a380-f2de5cfe1146',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: true,
      showATMFriendlyCards: false,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: true,
      showScanCardRegistration: true,
      showMarketingSiteLink: true,
      showFreeAtmFinderLink: false,
      showWUTransfers: true,
      showReloadLocationLink: true
    },
    misc: {
      distributor: 'meijer',
      layoutComponentOverride: false,
      loader: 'meijer',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#0A4D8C',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#44AD3A',
      marketingSiteUrl: 'https://www.meijerprepaidcard.com',
      marketingSiteLinkText: 'Meijerprepaidcard.com',
      locationSearchMax: '25',
      iOSAppId: '1176666487'
    },
    content: {
      signUpContent: "Don't have a card? Sign up today."
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
